@import '../../../01-settings/variables';

custom-dropdown.sc-input {
  padding: 0;
  position: relative;
  background-color: $white;
  display: block;

  &[disabled=''],
  &[disabled=true] {
    opacity: 0.55;
    cursor: not-allowed;

    &:hover {
      border-color: $black-60;
    }

    div:first-child {
      pointer-events: none;
    }
  }

  &:hover {
    border-color: $black-100;
    transition: all .2s ease-in;
  }

  div:first-child {
    padding: $XS $M;
    width: 100%;
    height: $XXXL;

    & > p {
      user-select: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      overflow: hidden;
      padding-right: 26px;
      padding-top: 3px;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;%7D .st3%7Bfill:%23333333;%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E"); /* stylelint-disable-line string-quotes */
        background-repeat: no-repeat;
        background-color: #fff;
        background-position: center;
        background-size: 60%;

        position: absolute;
        top: 6px;
        bottom: $form-border-radius;
        right: $form-border-radius;
        content: '';
        width: 26px;
      }
    }
  }

  &.sc-open {
    div:first-child {
      border: 1px solid $black-100;
      box-shadow: inset 0 0 0 1px $black-100;
      border-top-left-radius: $XS;
      border-top-right-radius: $XS;
      width: calc(100% + 3px);
      position: relative;
      top: -1px;
      left: -1px;
      background-color: $white;
    }

    div:first-child::after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 1px;
      right: 1px;
      border-bottom: 1px solid $black-40;
    }
  }

  & > div:last-child {
    display: none;
    position: relative;
    top: -1px;
    left: -1px;
    background-color: $white;
    border: 2px solid $black-100;
    border-radius: 0 0 $XS $XS;
    border-top: 0;
    width: calc(100% + 3px);
    z-index: 1;
    padding: $S $M $M $M;
    margin-top: -1px;

    label {
      padding-top: 1px;
    }
  }

  &.sc-open > div:last-child {
    display: block;
  }

  &[scrollable=''],
  &[scrollable=true] {
    div:last-child {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 150px;
    }
  }

  div label {
    margin-top: $S;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

}
