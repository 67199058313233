@import '../../../01-settings/variables';
@import '../../../02-sass-tools/link';

a,
.sc-link {
  @include link($size: M);
}

.sc-inline-link {
  @include link($variant: black);
}
