// TODO: can be cleaned up or maybe removed when using new spacing classes

@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-page-header {
  background-color: $black-20;
  margin-top: 25px;
  padding: $L $L $M;

  @include mqmax($viewportM - 1) {
    margin-top: 0;
    padding: $L;
  }
}

.sc-headline-with-breadcrumbs {
  background-color: $black-20;

  h1 {
    padding-left: $L;
  }
}
