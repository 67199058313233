@import '../01-settings/variables';
@import '../02-sass-tools/sass-mq';
@import '../02-sass-tools/fonts';

.sc-font-s {
  @include font(S, $line-height: 1.5);
}

.sc-font-m {
  @include font(M, $line-height: 1.5);
}

.sc-font-l {
  @include font(L, $line-height: 1.4);
}

.sc-font-xl {
  @include font(XL, $line-height: 1.3);

  @include mqmin($viewportM) {
    font-size: $XXL;
  }
}

.sc-font-xxl {
  @include font(XXL, $line-height: 1.25);

  @include mqmin($viewportM) {
    font-size: 32px;
  }
}

.sc-font-hero {
  @include font(hero, $line-height: 1.3);

  @include mqmin($viewportM) {
    font-size: $XXXL;
    line-height: 1.2;
  }
}

.sc-font-silent {
  color: $black-60; // gets overwriten by custom styles in CD
}

.sc-font-bold {
  font-weight: 600;
}

.sc-font-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.sc-font-error {
  color: $color-error;
  margin-top: 4px;
}

// DEPRECATED. Use sc-font-* classes instead. Don't remove yet due to compatibility.
h1.sc-font {
  padding: 0 $L; //TODO rewrite padding

  @include headline(XL, XXL);
}

h2.sc-font {
  @include headline(L, XL, true, true);
}

h3.sc-font {
  @include headline(M, XL, true, false);
}

h4.sc-font {
  @include headline(M, L, false, true);
}

h5.sc-font {
  @include headline(M, M, false, true);
}

/* How to use it with system fonts as fallback */
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
