@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-ordered-link-title {
  padding-bottom: $XL;

  @include mqmin($viewportM) {
    padding-bottom: 32px;
  }
}

.sc-ordered-link-list {
  counter-reset: list-index;
  width: 100%;
  column-count: 2;
  column-gap: $L;

  &__long {
    @include mqmin($viewportL) {
      column-count: 4;
    }
  }
}

.sc-ordered-link-list__li {
  padding: $S 0 $XS;
  border-bottom: 1px solid $black-30;
  break-inside: avoid;

  a {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      content: counter(list-index, decimal-leading-zero);
      counter-increment: list-index;
      color: $black-50;
      padding-right: $M;
      font-size: 20px;
    }
  }
}
