@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';
@import '../../../02-sass-tools/rotating-arrow';
@import '../../../02-sass-tools/utils';

.sc-expandable-box {
  border-top: 1px solid $black-30;

  @include mqmin($viewportM) {
    border-top: none;
  }

  &--adaptive {
    @include mqmin($viewportM) {
      border-bottom: none;

      .sc-expandable-box__content {
        display: block;
      }

      .sc-expandable-box__label {
        cursor: auto;

        &:after {
          display: none;
        }
      }
    }
  }

  &__toggle {
    display: none;

    &:checked {
      & ~ .sc-expandable-box__content {
        display: block;
      }

      & ~ .sc-expandable-box__title > .sc-expandable-box__label {
        &:after {
          @include rotating-arrow('up');
          margin-left: $L;
        }
      }
    }
  }

  &__label {
    padding: $XL 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      @include rotating-arrow('down');
      margin-left: $L;
    }

    @include mqmin($viewportM) {
      padding: $XXL 0;
    }
  }

  &__content {
    display: none;
    padding-bottom: $XL;
  }
}
