@import '../../../01-settings/variables';

.sc-unique-link-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  
  li {
    $colNumber: 2;
    $colMargin: $L;
    width: calc((100% / #{$colNumber}) - #{$colMargin} + (#{$colMargin} / #{$colNumber}));
    margin-right: $colMargin;

    border-bottom: 1px solid $black-30;
    padding-bottom: $S;
    margin-bottom: $S;

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    @include mqmin($viewportM) {
      $colNumber: 3;
      width: calc((100% / #{$colNumber}) - #{$colMargin} + (#{$colMargin} / #{$colNumber}));

      &:nth-child(2n+2) {
        margin-right: $L;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    a {
      display: block;
      position: relative;
      padding-left: calc(#{$XS} + 12px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:after {
        position: absolute;
        content: '';
        transform: rotate(270deg);
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;%7D .st3%7Bfill:%23333333;%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        width: $M;
        height: $M;
        left: 0;
        top: 5px;
      }
    }
  }
}
