@import '../../../01-settings/variables';
@import '../../../02-sass-tools/fonts';

//TODO refactor this crap - https://github.com/AutoScout24/classified-list/blob/master/ui/scss/filters/exteriorColors.scss

@mixin color-selector-color($color, $checkedColor:'#333333') {
  $checkedColor: str-slice(inspect($checkedColor), 2);

  & + label {
    &::before {
      background: $color;
      background-position: 50% 50%;
    }
  }

  &:checked + label::before {
    //attention: #{$checkedColor} is inside svg+xml with a placeholder
    background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2226%22%20viewBox%3D%220%200%2026%2026%22%20width%3D%2226%22%3E%3Cstyle%20id%3D%22style3%22%20type%3D%22text%2Fcss%22%3E.st0%7Bfill%3A%23#{$checkedColor}%3B%7D%3C%2Fstyle%3E%3Cpath%20id%3D%22polygon7%22%20class%3D%22st0%22%20d%3D%22M18.8%206.8l-7.9%207.8-3.8-3.7L5%2013l6%206L21%209z%22%2F%3E%3C%2Fsvg%3E'), $color;
    background-position: 50% 50%;
  }

  &[type='radio']:checked + label::before {
    //attention: #{$checkedColor} is inside svg+xml with a placeholder
    background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2026%2026%22%20enable-background%3D%22new%200%200%2026%2026%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.st0%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%23#{$checkedColor}%3B%7D%3C%2Fstyle%3E%3Ccircle%20cy%3D%2213%22%20cx%3D%2213%22%20r%3D%225%22%20class%3D%22st0%22%2F%3E%3C%2Fsvg%3E'), $color;
    background-position: 50% 50%;
  }
}

.sc-color-selector {
  input[type='radio'],
  input[type='checkbox'] {
    &.sc-input,
    &.sc-input-m,
    &.sc-input-l {
      $size: 17px;

      & + label {
        @include font(M);
        line-height: 32px;
        padding-left: 40px;
        padding-right: 8px;
        margin: $M 0;

        &::before {
          border: none;
          width: 26px;
          height: 26px;
          top: 3px;
          left: 3px;
        }

        &::after {
          border: 1px solid $black-60;
          content: '';
          display: block;
          background-size: cover;
          position: absolute;

          width: 32px;
          height: 32px;
          top: 0;
          left: 0;
          border-radius: $size + $M;
        }
      }

      &:disabled + label {
        cursor: not-allowed;
        color: $black-40;

        &::after {
          opacity: 0.55;
        }
      }

      &:enabled + label:hover::after {
        border-color: $black-100;
      }

      &:focus:enabled + label::after {
        border: 1px solid $black-100;
        box-shadow: inset 0 0 0 1px $black-100;
      }

      &:focus:enabled + label::before {
        border: none;
        box-shadow: none;
      }

      &:checked + label::before {
        text-align: center;
        padding-top: 1px;
        line-height: $size + $XS;
        background-repeat: no-repeat;
      }
    }
  }

  input[type='checkbox'] {
    &.sc-input {
      & + label {
        &::before,
        &::after {
          border-radius: $form-border-radius !important; //TODO: get rid of important
        }
      }
    }
  }
}
