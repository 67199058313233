@import '../../../01-settings/variables';

.sc-google-maps {
  position: relative;
  display: block;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
