@import '../../../01-settings/variables';

$border: 1px solid $black-30;

.sc-table {
  table {
    table-layout: fixed;
    width: 100%;
    border: $border;
    overflow-x: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  tbody {
    tr:last-child td {
      border-bottom: 0 none;
    }
    tr:nth-child(odd) {
      background-color: $black-20;
    }
  }

  td, th {
    padding: $XL $L;
    text-align: left;
    border-bottom: $border;
    border-right: $border;
  }

}

.sc-table__empty {
  border-right: $border;
}

.sc-table__scrollable {
  table {
    table-layout: fixed;
    overflow-x: auto;
    width: 100%;
    display: block;

    thead,
    tbody {
      display: block;
    }

    tr {
      display: flex;
      position: relative;
      
      th,
      td {
        flex-basis: calc(50% - 16px);
        max-width: 50%;
        flex-grow: 1;
        flex-shrink: 0;
        flex-wrap: nowrap;
        display: block;
        background-color: $white;
        &:nth-last-child(1) {
          border-right: 0;
        }
        @include mqmin($viewportM) {
          flex-basis: calc(33.3333% - 12px);
        }
        @include mqmin($viewportL) {
          flex-basis: calc(25% - 8px);
        }
      }

      &:nth-child(odd) {
        background-color: $black-20;
        
        td {
          background-color: $black-20;
        }
      }
    }
  }
}

.sc-table__title {
  color: $black-60;
  border-right: $border;
}
