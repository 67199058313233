@import '../../../01-settings/variables';
@import '../../../02-sass-tools/input';

.sc-stepper {
  display: flex;
}

.sc-stepper-input {
  @include input;

  border: 1px solid $black-60;
  border-left: 0;
  border-right: 0;
  width: 50%;
  text-align: center;
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield; // Don't show spinners in firefox
}

.sc-stepper-button {
  cursor: pointer;
  outline: none;
  padding: 5px 10%;
  color: $black-100;
  background-color: $white;
  font-size: 100%;
  font-weight: 600;
  border: 1px solid $black-60;
  border-radius: 4px;
  width: 25%;

  &:hover, &:focus {
    background-color: $black-30;
    transition: background-color .2s ease-in;
  }

  &:active {
    background-color: $black-40;
  }

  &-decrement {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-increment {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Don't show spinners in webkit browsers
.sc-stepper-input::-webkit-inner-spin-button,
.sc-stepper-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  margin: 0;
}
