@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';
@import '../../../02-sass-tools/link';
@import '../../../02-sass-tools/rotating-arrow';

$spy-height: 55px;
$flyout-arrow-highlighted: "data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%231166A8%7D .st3%7Bfill:%231166A8%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%231166A8stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E";

@mixin visibleOnML {
  opacity: 1;
  transform: translateY(0);
}

@mixin visibleOnS {
  opacity: 1;
  transform: translateY(-$spy-height);
}

.sc-spy-navigation {
  $nav: &;
  box-sizing: border-box;
  max-width: $viewportXL;
  border-bottom: 1px solid $black-30;
  position: relative;
  background-color: white;
  z-index: 2;

  &:before {
    top: 0;
    left: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &--expanded {
    #{$nav}__links {
      height: #{$spy-height - 1};

      @include mqmax($viewportM) {
        border-bottom: 1px solid $black-30;
      }
    }

    #{$nav}__wrapper {
      overflow-y: visible;
      overflow-x: visible;
    }

    #{$nav}__link {
      position: static;
      padding: 12px $L 14px;
      @include visibleOnML;

      &:first-child {
        position: static;
        padding: $L $L 14px;
        @include visibleOnML;
      }

      &:nth-child(2) {
        padding: $L $L 14px;
      }

      &:last-child {
        padding: 12px $L $L;
      }

      @include mqmin($viewportM) {
        padding: $L 24px 14px 24px;

        &:first-child {
          padding: $L 24px 14px $L;
        }

        &:nth-child(2) {
          padding: $L 24px 14px 24px;
        }

        &:last-child {
          padding: $L $L 14px 24px;
        }
      }
    }

    #{$nav}__toggle {
      &:after {
        @include rotating-arrow('up');
        background-image: url($flyout-arrow-highlighted);
      }

      &--visible {
        height: $spy-height;
        border-left: 1px solid $black-30;
      }

      &__text {
        color: $google-blue;
      }
    }
  }

  &--sticked {
    position: fixed;
    width: 100%;
    max-width: $viewportXL;
    top: 0;
    background-color: white;
    z-index: 21;

    &:before {
      box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.15);
    }

    #{$nav}__links {
      background: white;
      @include mqmax($viewportM) {
        border-bottom: 1px solid $black-30;
      }
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    height: #{$spy-height - 1};
  }

  &__toggle {
    $toggle: &;

    position: absolute;
    top: 0;
    right: 0;
    height: $spy-height;
    padding: $L $L #{$M + 1};

    @include mqmax(#{$viewportM - 1}) {
      width: $spy-height;
    }

    @include mqmin($viewportM) {
      visibility: hidden;
    }

    &:after {
      position: absolute;
      margin-top: 2px;
      right: $L;
      top: $XL;
      @include rotating-arrow('down');
    }

    &:hover {
      #{$toggle}__text {
        color: $google-blue;
      }
    }

    &__text {
      visibility: hidden;
    }

    &--visible {
      visibility: visible !important;
      width: auto;
      background: white;

      #{$toggle}__text {
        visibility: visible;
        margin-right: $XXL;
        cursor: pointer;
      }
    }
  }

  &__links {
    display: inline-block;
    white-space: nowrap;
    width: 100%;

    @include mqmin($viewportM) {
      width: auto;
    }
  }

  &__link {
    $link: &;

    @include link(M, $variant: black);
    display: block;
    position: absolute;
    padding: 12px $L 12px;
    border-bottom: 2px solid transparent;
    transition: color 0.2s ease-in;
    background: white;
    top: 100%;
    width: 100%;
    opacity: 0;

    &:first-child {
      padding: $L $L 14px;
      @include visibleOnS;
    }

    &:nth-child(2) {
      padding: $L $L 15px;
    }

    &:last-child {
      padding: 12px $L $L;
    }

    &:last-of-type {
      @include mqmax($viewportM - 1) {
        border-bottom: 1px solid $black-30;
      }
    }

    @include mqmin($viewportM) {
      @include visibleOnML;
      display: inline-block;
      padding: $L 24px 12px 24px;
      position: static;
      width: auto;

      &:first-child {
        @include visibleOnML;
        padding: $L 24px 14px $L;
      }

      &:nth-child(2) {
        padding: $L 24px 14px 24px;
      }

      &:last-child {
        @include visibleOnML;
        padding: $L $L 14px 24px;
      }
    }

    &--active {
      color: $google-blue;
      border-bottom: 2px solid $google-blue !important;
      padding-top: 16px;
      padding-bottom: 15px;
      padding-left: 24px;
      padding-right: 24px;

      &:first-child,
      &:last-child {
        padding-top: 16px;
        padding-bottom: 14px;
        padding-left: $L;
        padding-right: $L;
        top: $spy-height;
      }

      @include visibleOnS;

      @include mqmin($viewportM) {
        @include visibleOnML;
        padding-bottom: 14px;

        &:first-child {
          padding-left: 16px;
          padding-right: 24px;
        }

        &:last-child {
          padding-left: 24px;
          padding-right: 16px;
        }
      }
    }
  }
}
