@import '../../../01-settings/variables';

$imgMaxWidth: 263px;

.sc-content-teaser {
  display: flex;
  flex-direction: column;
  order: 2;
  background-color: $white;
  
  @include mqmin($viewportM) {
    flex-direction: row;
  }
}

.sc-content-teaser__introtext {
  position: relative;
}

.sc-content-teaser__linkcover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 2;
}

.sc-content-teaser__pic {
  margin-bottom: $L;

  img {
    width: 100%;
  }

  @include mqmin($viewportM) {
    flex-basis: $imgMaxWidth;
    margin-right: $L;
    margin-bottom: 0;
  }

  @include mqmin($viewportL) {
    flex: 0 0 $imgMaxWidth;
  }
}

.sc-content-teaser__content {
  @include mqmin($viewportM) {
    flex: 1 1 449px;
  }
}

.sc-content-teaser__category {
  color: $color-highlight-info;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-bottom: $S;
}

/* hightlight */
.sc-content-teaser--highlighted {
  margin-left: $L;
  margin-right: $L;
  flex-direction: column;
  order: 1;

  @include mqmin($viewportM) {
    margin-left: 0;
    margin-right: 0;
  }
  
  .sc-content-teaser__pic {
    flex-basis: auto;
    margin-left: -$L;
    margin-right: -$L;
    margin-bottom: $XL;

    @include mqmin($viewportM) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sc-content-teaser__content {
    flex-basis: auto;
  }
}

/* content teaser list */
.sc-content-teaser-list {
  display: flex;
  flex-direction: column;
  background-color: $white;

  .sc-content-teaser {
    border-bottom: 1px solid $black-30;
    padding-bottom: $XXL;
    margin-left: $L;
    margin-right: $L;
  
    @include mqmin($viewportM) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sc-content-teaser:not(:last-child) {
    margin-bottom: $XXL;
  }

  @include mqmin($viewportM) {
    .sc-content-teaser {
      padding-bottom: $XXXL;
    }
    
    .sc-content-teaser:not(:last-child) {
      margin-bottom: $XXXL;
    }
  }
}
