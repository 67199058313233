@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-benefit-group {
  padding-left: $L;
  padding-right: $L;
  
  @include mqmin($viewportM) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: $XL;
    padding-left: 0;
    padding-right: 0;
  }

  @include mqmin($viewportL) {
    flex-wrap: nowrap;
    padding-left: $S;
    padding-right: $S;
  }

  .sc-benefit {
    @include mqmin($viewportM) {
      width: 50%;
      padding-right: $L;
      padding-left: $L;
    }

    @include mqmin($viewportL) {
      padding-right: $S;
      padding-left: $S;
    }

    .sc-disruptor {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.sc-benefit {
  text-align: center;
  padding-bottom: $XXXL;
  background-color: $white;
}