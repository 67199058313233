@import '../../../01-settings/variables';

@keyframes closing {
  0% {
    transform: scale(1, 1);
  }
  // 10% {
  //   transform: scale(1.2,1.2);
  // }
  100% {
    transform: scale(0, 0);
  }
}

.sc-tag {
  display: inline-block;
  background-color: $black-20;
  border-radius: 20px;
  margin: 2px $XS 2px 0;
  padding: 0 0 0 $S;

  &__label {
    display: inline-block;
  }

  &__close {
    display: inline-block;
    padding: $XS $S $XS $XS;
    cursor: pointer;

    & svg {
      position: relative;
      width: $L;
      height: $L;
      border: 1px solid $black-100;
      border-radius: 50%;
      padding: 2px;
      margin-left: 2px;
      top: 3px;
      fill: $black-100;
      stroke: $black-100;
    }
  }

  &.closing {
    animation: closing 0.2s 1 normal ease-in-out;
    // transition: opacity 0.5s ease-in-out;
  }
}
