// TODO: remove and use spacing classes instead
.sc-label {
  display: block;
  margin-top: $XL;
  margin-bottom: $XS;
}

.sc-label-left {
  display: inline-block;
  margin-right: $XS;
  vertical-align: top;
}