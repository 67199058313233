@import '../../../01-settings/variables';

.sc-tooltip-content {
  opacity: 0;
  transition: opacity .3s linear;
  display: none;
  max-width: 220px;
  background-color: rgba($black-120, .75); // we want only transparent background, but not text
  border-color: $black-120;
  color: $white;
  padding: $M;
  position: absolute;
  z-index: 2;
  border-radius: 4px;

  a {
    color: $white;
    text-decoration: underline;

    &:active {
      color: $black-50;
    }

    &:hover {
      color: $black-40;
    }
  }

  &.sc-tooltip-shown {
    display: block;
  }

  &.sc-fade-in {
    opacity: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: rgba($black-120, .75);
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
  }

  &.sc-tooltip-bottom:after {
    border-bottom-color: rgba($black-120, .75);
    border-top-color: transparent;
    top: auto;
    bottom: 100%;
  }

  &.sc-tooltip-right:after {
    left: 10px;
    margin: 0;
  }

  &.sc-tooltip-left:after {
    right: 10px;
    left: auto;
    margin: 0;
  }
}
