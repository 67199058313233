@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

//TODO remove or move to grid
$breakpoint-S: 640;
$breakpoint-M: 720;
$breakpoint-L: 1024;
$breakpoint-XL: 1099;

.sc-card {
  display: block;
  position: relative;
  overflow: hidden;
  flex: 0 0 50%;

  @include mqmin($breakpoint-S) {
    flex: 0 0 33.3%;
  }

  @include mqmin($breakpoint-M) {
    flex: 0 0 25%;
  }

  @include mqmin($breakpoint-L) {
    flex: 0 0 20%;
  }

  &__link {
    display: block;
    overflow: hidden;
    border: 1px solid $black-30;
    border-radius: $XS;
    transition: border 0.3s ease, color 0.2s ease-in;
    margin-top: $L;
    margin-left: $L;

    &:hover {
      border: 1px solid $black-60;
    }
  }

  &__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: $L $XL;
    text-align: center;
    background: $white;
    font-size: 13px;

    @include mqmin($breakpoint-S) {
      font-size: 16px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}

.sc-cards {
  $list: &__list;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    margin: #{-$L} 0 0 #{-$L};
    background-color: $white;
  }

  &__show {
    margin-top: $XXL;
    text-align: center;
    cursor: pointer;
    color: $color-link;

    &:hover {
      color: $blue-100;
    }

    @include mqmin($breakpoint-S) {
      display: none;
      padding: $XXXL $L 60px;
    }

    @include mqmax($breakpoint-S - 1) {
      // hack. @include mq problem, it floats top when rendering
      display: block;
    }
  }

  &__state {
    display: none;

    & ~ .sc-cards__list {
      .sc-card:nth-child(1n + 5) {
        display: none;
        @include mqmin($breakpoint-S) {
          display: block;
        }
      }
    }

    &:checked {
      & ~ .sc-cards__show {
        display: none;
      }

      & ~ .sc-cards__list {
        .sc-card {
          display: block;
        }
      }
    }
  }
}
