@import '../../../01-settings/variables';
@import '../../../02-sass-tools/fonts';
@import '../../../02-sass-tools/link';
@import '../../../02-sass-tools/sass-mq.scss';

$flyout-arrow: "data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;%7D .st3%7Bfill:%23333333;%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E";
$flyout-arrow-highlighted: "data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%231166A8%7D .st3%7Bfill:%231166A8%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%231166A8stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%231166A8stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E";

$symbol-highlighted: $google-blue;
$header-height: 71px;

// Dealer Team is using the styles from mixing because their header has different breakpoints
@mixin sc-navigation-v2-mixin($breakpoint) {
  background-color: $white;
  -webkit-tap-highlight-color: transparent;
  border-bottom: solid 1px $black-30;

  a {
    white-space: nowrap;
  }

  button {
    &.sc-link,
    &.sc-inline-link {
      background-color: transparent;
      border: none;
      font-size: $L;
    }
  }

  &.open {
    .sc-btn-mobile-menu {
      color: $symbol-highlighted;

      span {
        background: transparent;

        &::before {
          transform: rotate(45deg) translate(3px, 5px);
          background: $symbol-highlighted;
        }

        &::after {
          transform: rotate(-45deg) translate(3px, -6px);
          background: $symbol-highlighted;
        }
      }
    }

    nav {
      max-height: 2200px;
      transition: max-height 0.5s ease-in;
    }
  }

  // Sandwich Menu Button
  .sc-btn-mobile-menu {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    transition: 0.2s ease;
    border: 0;
    background-color: transparent;
    outline: 0;
    padding: $S 0;
    margin-top: 1px;
    max-height: 36px; // to assure IE11 sizes

    @include font(M);

    span {
      margin-left: $S;
      width: 20px;
      height: 2px;
      background-color: $black-100;
      transition: background-color 0.1s;
      position: relative;

      &::before,
      &::after {
        transition: 0.2s ease-in-out;
        position: absolute;
        background: $black-100;
        content: '';
        width: 20px;
        height: 2px;
        left: 0;
      }

      &::before {
        top: -6px;
      }

      &::after {
        top: 6px;
      }
    }
  }

  // Expandable Menu Items
  .submenu {
    padding: 0 0 7px 24px;
    transition: 0.3s ease-out;
    display: none;

    &.visible-on-mobile {
      display: block;
    }
  }

  li.open .submenu {
    display: block;
  }

  // Subheadline for Menu Items
  .subheadline {
    @include font(S, $silent: true);
    padding: $XS;

    &:hover {
      cursor: default;
    }
  }

  .left,
  .right {
    padding: 0 $L;
    border: none;
  }

  // Left Navigation Bar
  .left {
    /* direct children on top level */
    > li {
      /* draw line between top level li */
      &::before {
        border-top: 1px solid $black-30;
      }
      &::before,
      &::after {
        margin: 0 0 $S;
        height: 1px;
        line-height: 1px;
        width: 100%;
        font-size: 0;
        display: block;
        content: ' ';
      }
    }
    /* draw line between top level li */
    &::after {
      margin: 0 0 $S;
      height: 1px;
      line-height: 1px;
      width: 100%;
      font-size: 0;
      display: block;
      border-top: 1px solid $black-30;
      content: ' ';
    }

    a {
      display: block;
      text-decoration: none;
      padding: $S 20px;
      white-space: normal;
      @include link(M, $variant: $black-120);

      &:focus {
        color: $symbol-highlighted;
      }
    }
  }

  // Right Navigation Bar
  .right {
    padding-bottom: 10px;

    .title {
      display: none;
    }

    .submenu {
      max-height: none;
      padding-left: 0;
    }

    .myaccount {
      padding-left: 10px;
    }

    .myaccount a:not(.sc-link):not(.button-with-subtitle) {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: $S 0;
      @include link(M, $variant: $black-120);
    }
  }

  // Menubar Item title
  .title {
    padding: $S 10px;
    outline: 0;
    position: relative;
    text-align: left;

    &::after {
      content: '';
      position: absolute;
      outline: 0;
      right: 0;
      margin-top: 6px;
      margin-right: $L;
      width: 12px;
      height: 12px;
      background: url($flyout-arrow) no-repeat center center;
      color: $symbol-highlighted;
    }

    &:focus {
      color: $symbol-highlighted;

      &::after {
        background-image: url($flyout-arrow-highlighted) no-repeat center center;
      }
    }
  }

  li {
    &.open .title::after,
    .title::after {
      transition: background 0.2s ease-in;
    }

    &.open .title::after,
    .title:hover::after {
      background-image: url($flyout-arrow-highlighted) no-repeat center center;
    }

    &.open {
      ul {
        max-height: 2000px;
      }
    }
  }

  /* Special empty li separator */
  .separator {
    box-sizing: border-box;
    height: 1px;
    margin: $M 0;
    border-top: 1px solid $black-30;
    display: block;
  }

  // Menubar Item subtitle
  .subtitle {
    display: none;
  }

  nav {
    transition: max-height 0.5s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px $L;
    height: 35px;
    box-sizing: content-box;

    .icon-auto24,
    as24-icon {
      width: 126px;
      height: 35px;
      display: flex;

      svg {
        width: 100%;
      }
    }
  }

  /* Optional functionality */
  .country-selector {
    display: none;
  }

  /* Optional functionality */
  .button-with-subtitle {
    ~ span:first-of-type {
      display: block;
      font-size: 13px;
      padding-top: 8px;
      padding-bottom: 8px;
      white-space: nowrap;

      a {
        display: inline;
        font-size: 13px;
        padding: 0;
      }
    }
  }

  @media (min-width: $breakpoint) {
    .open .submenu:not(.extended) {
      min-width: 165px;
    }
    .open .submenu.extended {
      white-space: nowrap;

      & > * {
        max-width: 100%;
        display: inline-block;
        vertical-align: top;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint) {
    display: flex;
    height: $header-height;

    .sc-btn-mobile-menu {
      display: none;
    }

    .bar {
      padding: $L;

      .icon-auto24,
      as24-icon {
        width: 148px;
        height: 39px;
        display: inline-block;
      }
    }

    .subheadline {
      padding: $XS 0 $S 0;
    }

    .submenu {
      &.visible-on-mobile {
        display: none;
      }

      position: absolute;
      transition: none;
      max-height: 0;
      background-color: $white;
      /* top value has to be equivalant to the header's height - border size, to hide the border between header and submenu */
      top: $header-height - 1px;
      border: 1px solid $black-30;
      left: -1px;
      padding: $M $XXL;
      overflow: hidden;

      a {
        padding-left: 0;
        padding-right: 0;
        white-space: nowrap;
      }

      .extended {
        display: flex;
      }
    }

    .left-flyout {
      .submenu {
        right: -1px;
        left: auto;
      }
    }

    li {
      position: relative;
      border: 1px solid $white;
      border-width: 0 1px;

      &.open {
        overflow: visible;
        border: 1px solid $black-30;
        border-width: 0 1px;

        &::after {
          display: block;
          content: '';
          height: 10px;
          background-color: $white;
          width: 100%;
          position: absolute;
          bottom: -10px;
          left: 0;
        }

        .submenu {
          transition: max-height 1s ease-out;
          max-height: 1000px;
        }
      }
    }

    .subtitle {
      @include font(S, $silent: true);

      display: inline-block;
      position: absolute;
      padding-left: 11px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: -8px;
      max-width: 150px;
    }

    .title {
      padding-right: 18px;
      margin-right: 4px;

      &::after {
        margin-right: 4px;
      }
    }

    .right {
      .submenu {
        padding-left: $XXL;
      }

      .submenu--login {
        min-width: 258px;
      }

      .title {
        display: block;
      }
    }

    /* Remove line between top level li */
    .left {
      &::after {
        display: none;
      }

      > li:not(.open)::after {
        display: none;
      }

      > li {
        &::before {
          display: none;
        }

        > a {
          padding-bottom: $S;
          line-height: normal;
        }
      }
    }

    .left,
    .right {
      padding: 0;
      display: flex;

      > li {
        padding: $L;
        padding-left: $M;
        padding-right: $M;
      }
    }

    nav {
      max-height: none;
      display: flex;
      overflow: visible;
      justify-content: space-between;
      flex-basis: 100%;
      z-index: 130;
    }

    /* Optional functionality */
    .button-with-subtitle {
      > a {
        min-width: 200px;
      }

      span {
        padding-top: $S;
      }
    }

    /* Optional functionality */
    li.country-selector {
      display: list-item;

      .title {
        padding-top: 9px;
        padding-bottom: 0;

        &::after {
          top: 8px;
        }
      }

      as24-icon {
        width: 21px;
        margin-top: 2px;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        @include link(M, $variant: $black-120);
        padding: 8px 0;

        span {
          margin-left: $M;
          line-height: 1;
        }

        ~ span ~ a {
          padding: 4px 0;
        }
      }
    }
  }

  .hidden-on-mobile {
    @include mqminmax(0, $breakpoint - 1) {
      display: none !important;
    }
  }
}
