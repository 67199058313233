@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-tabs--with-text {
  @include mqmin($viewportM) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.sc-tab {
  height: 52px;
  background-color: $white;
  width: 100%;
  border-bottom: 1px solid $black-30;
  opacity: .9;
  -webkit-tap-highlight-color: transparent; // to prevent default blinking behavior

  &:focus {
    color: $google-blue;
  }

  @include mqmin($viewportM) {
    width: auto;
    order: 1;
    flex-grow: 1;
    margin: $XS 0 1px 1px;
    padding-top: 2px;
    border: none;

    &:first-child {
      margin-left: 0;
    }
  }
}

.sc-tab--with-text--active {
  border-bottom: none;
  height: 48px;
  color: $google-blue;
  border-top: 4px solid $google-blue;
  opacity: 1;
  outline: none; // to prevent bottom line

  @include mqmin($viewportM) {
    height: 57px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.sc-tabs__content {
  border-bottom: 1px solid $black-30;
  width: 100%;

  @include mqmin($viewportM) {
    order: 2;
  }
}