@import 'fonts';
@import '../01-settings/variables';

@mixin link($size: null, $variant: blue) {
  cursor: pointer;
  text-decoration: none;
  transition: color $transition-time ease-in;
  background: none;
  border: none;

  $link-colors: (
    blue: (
      normal: $color-link,
      hover: $google-blue-90,
      active: $google-blue-90
    ),
    black: (
      normal: $black-100,
      hover: $google-blue,
      active: $google-blue
    )
  );

  $color: map-get(map-get($link-colors, $variant), normal);
  color: $color;

  &:hover {
    $hover-color: map-get(map-get($link-colors, $variant), hover);
    color: $hover-color;
  }

  &:active {
    $active-color: map-get(map-get($link-colors, $variant), active);
    color: $active-color;
  }

  @if ($size) {
    @include font($size);
  }
}
