@import '../../../01-settings/variables';

.sc-inlined-info {
  position: relative;
}

.sc-inlined-info__addon {
  position: absolute;
  right: 10px;
  top: 9px;
  color: $black-60;
  background-color: $white;
  padding-left: 4px;
}
