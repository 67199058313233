.sc-shimmer {
  background: $black-20;
  background-image: linear-gradient(to right, $black-20 0%, #f0f0f0 20%, $black-20 40%, $black-20 100%);
  background-repeat: no-repeat;
  background-size: 768px 580px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}
