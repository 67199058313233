@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-small-footer {
  color: $black-60;
  padding-bottom: $M;
  padding-top: $L;
  padding-left: $L;
  padding-right: $L;
  background-color: $black-30;
  overflow-y: hidden; // to assure that IE11 doen't have a scroll-bar

  p {
    margin-top: 8px;
  }

  as24-icon {
    width: 75px;
    height: 35px;
  }

  a:nth-child(2), a:nth-child(3) {
    margin-left: $M;
  }

  .sc-pull-left, .sc-pull-right {
    @include mqmax($viewportM - 1) {
      width: 100%;
    }
  }
}

.footer-content-container .app-links a:hover svg{
  fill: $yellow !important;
}
