@import '../../../01-settings/variables';
@import './navigation-v2-mixin.scss';

$navigation-breakpoint: 1000px;

.sc-navigation-v2 {
  @include sc-navigation-v2-mixin($navigation-breakpoint);

  button.smyle-header-menu {
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: $navigation-breakpoint) {
      padding: 0 20px 0 0;
    }

    .title {
      &::after {
        margin-top: 0;
      }
    }
  }

  button.smyle-header-menu:after {
    margin-top: 0;
  }

  .smyle-header-icon {
    width: 55px;
    height: 33px;
    margin-right: 8px;
  }
}

.skip-main {
  position: absolute;
  width: 0;
  overflow: hidden;

  &:focus,
  &:active {
    color: $white;
    display: block;
    background-color: $black-100;
    width: 30%;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid $black-60;
    text-align: center;
    font-size: 1.2em;
    z-index: 140; // more than header
  }
}
