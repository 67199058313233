@import '../../../01-settings/variables';

.sc-menu-tab {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: $L;
  padding-top: $S;
  padding-bottom: $S;
  background-color: $white;
  border-bottom: solid 1px $black-30;
  min-height: 60px;
  color: $black-120;
}

.sc-menu-tab--active {
  border-left: solid $XS $color-link;
  padding-left: $M;
  color: $color-link;

  a {
    color: $color-link;
  }
}

.sc-menu-tab--active::after {
  content: '';
  position: absolute;
  right: -$L;
  top: $L;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: $M solid #fff;
  clear: both;
}

.sc-menu-tab__text {
  display: flex;
  flex-direction: column;
  width: 90%;
}

// add padding only if icon is present
.sc-menu-tab__icon + .sc-menu-tab__text {
  padding-left: $L;
}

.sc-menu-tab__icon {
  width: $XL;
  height: $XL;
  background-color: $black-30;
  border-radius: 10px;
  align-self: center;
}

.sc-menu-tab__icon--success {
  background-color: #009f00;
}

.sc-menu-tab__icon--info {
  background-color: #ffbe00;
}

.sc-menu-tab__icon--error {
  background-color: #e70315;
}

.sc-menu-tab__icon__hook {
  width: $M;
  height: $M;
  float: left;
  margin-left: $XS;
  margin-top: -1px;

  svg {
    fill: $white;
    stroke: $white;
    stroke-width: 1;
  }
}
