@import '../../../01-settings/variables';

$notification-success: $black-100;
$notification-success-border: $success-100;
$notification-success-background: $success-20;

$notification-info: $black-100;
$notification-info-border: $warning-100;
$notification-info-background: $warning-20;

$notification-error: $black-100;
$notification-error-border: $error-100;
$notification-error-background: $error-20;

$notification-tip: $black-100;
$notification-tip-border: $info-100;
$notification-tip-background: $info-20;

$types: (
  'success': (
    color: $notification-success,
    color-border: $notification-success-border,
    background-position-y: $M,
    background-color: $notification-success-background,
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.7,8.3c-0.4-0.4-1-0.4-1.4,0L11,13.6l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3 c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6-6C18.1,9.3,18.1,8.7,17.7,8.3z'/%3E%3Cpath d='M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8 S16.4,20,12,20z'/%3E%3C/svg%3E%0A")
  ),
  'information': (
    color: $notification-info,
    color-border: $notification-info-border,
    background-position-y: $L,
    background-color: $notification-info-background,
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8 S16.4,20,12,20z'/%3E%3Cpath d='M12,11c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-5C13,11.4,12.6,11,12,11z'/%3E%3Cpath d='M12,6c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1V7C13,6.4,12.6,6,12,6z'/%3E%3C/g%3E%3C/svg%3E")
  ),
  'error': (
    color: $notification-error,
    color-border: $notification-error-border,
    background-position-y: $L,
    background-color: $notification-error-background,
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M12,7c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1V8C13,7.4,12.6,7,12,7z'/%3E%3Cpath d='M12,15c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1C13,15.4,12.6,15,12,15z'/%3E%3Cpath d='M21.6,16.1l-6-11.5c-0.7-1.3-2-2.1-3.5-2.1S9.1,3.3,8.5,4.6l-6,11.5c-0.7,1.3-0.6,2.7,0.1,3.9 C3.3,21.3,4.5,22,6,22H18c1.4,0,2.7-0.7,3.4-1.9C22.2,18.9,22.2,17.4,21.6,16.1z M19.8,19c-0.4,0.6-1,1-1.7,1H6 c-0.7,0-1.3-0.4-1.7-1c-0.4-0.6-0.4-1.3-0.1-2l6-11.5c0.4-0.7,1-1.1,1.8-1.1s1.4,0.4,1.8,1.1l6,11.5C20.1,17.7,20.1,18.4,19.8,19z' /%3E%3C/g%3E%3C/svg%3E%0A"),
  ),
  'tip': (
    color: $notification-tip,
    color-border: $notification-tip-border,
    background-position-y: $L,
    background-color: $notification-tip-background,
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.1,3.9c-1.8-1.5-4.2-2.2-6.6-1.7C6.8,2.8,4,6.2,4,10c0,2.5,1.1,4.8,3.3,6.8C7.8,17.3,8,17.8,8,18.4v1.4 c0,1.3,0.9,2.2,2,2.2h4c1.1,0,2-0.9,2-2v-1.6c0-0.6,0.2-1.1,0.7-1.5c2.2-2,3.3-4.4,3.3-6.9C20,7.6,19,5.4,17.1,3.9z M14,20l-4,0 c0,0,0-0.1,0-0.2v-1.4c0-0.1,0-0.3,0-0.4H14c0,0.1,0,0.3,0,0.4V20z M15.3,15.4c-0.2,0.2-0.4,0.4-0.5,0.6H13v-5h2c0.6,0,1-0.4,1-1 s-0.4-1-1-1H9c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v5H9.2c-0.2-0.2-0.3-0.4-0.5-0.6C6.9,13.8,6,12,6,10c0-2.9,2.1-5.4,4.9-5.9 C11.3,4,11.6,4,12,4c1.4,0,2.7,0.5,3.8,1.4C17.2,6.5,18,8.2,18,10C18,12,17.1,13.8,15.3,15.4z'/%3E%3C/svg%3E"),
  )
);

@mixin notification($type) {
  color: map-get($type, color);
  border-bottom: 2px solid map-get($type, color-border);
  background-color: map-get($type, background-color);
}

@mixin notification-icon($type) {
  background-image: map-get($type, background-image);
  background-repeat: no-repeat;
  background-position: $L map-get($type, background-position-y);
}

as24-notification, .sc-as24-notification {
  display: block;
  overflow: hidden;
  width: 100%;
  position: relative;
  max-height: 0;
  transition: max-height 0.5s ease-out;

  &.prefade {
    transition-duration: 0s;
  }

  &.show {
    transition: max-height 0.5s ease-in;
    max-height: 320px; /* ToDo: could be set in js for better accuracy */
  }

  @each $name, $value in $types {
    &[type=#{$name}] {
      > div {
        @include notification($value);
      }

      .icon {
        @include notification-icon($value);
      }

      as24-icon[type='close'] {
        width: 16px;
        height: 16px;

        > svg {
          fill: map-get($value, color);
          transition: fill $transition-time ease;

          &:hover {
            fill: map-get($value, color-hover);
          }
        }
      }
    }
  }

  > div {
    padding: $M ($XXL + $L);

    > span {
      padding: 0;
      margin: 0;
    }

    > button {
      position: absolute;
      top: 16px;
      right: 16px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
    }

    > a {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
}

as24-notification:not(.show), .sc-as24-notification:not(.show) {
  display: none;
}

.sc-notification-container {
  z-index: 120; // overlays ads
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.sc-notification-observe-item {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  z-index: -1;
  visibility: hidden;

  > .observe-item-child {
    position: absolute;
    left: 0;
    top: 0;
  }
}
