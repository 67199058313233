.sc-list {
  list-style-position: outside;
}

.sc-list li {
  margin-left: $XL;
  margin-bottom: $XS;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.sc-list--disc {
  list-style-type: disc;
}

.sc-list--decimal {
  list-style-type: decimal;
}
