// TODO: Does it makes sense to have this element without any collapse functionality?
@import '../../../02-sass-tools/rotating-arrow';

[data-toggle='arrow'] {
  &::after {
    cursor: pointer;
    display: inline-block;
    @include rotating-arrow('down');
  }

  &.open::after {
    transform: rotate(-180deg);
  }
}
