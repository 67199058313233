@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-tabs {
  text-align: center;
  border-bottom: 1px solid $black-30;

  @include mqmin(480px) {
    max-width: 480px;
  }
}

.sc-tabs--with-icon {
  display: flex;
  align-items: flex-end;
}

.sc-tabs__content {
  display: none;
  background-color: $white;
  padding: $XL $L;

  &.sc-tabs__content--visible {
    display: block;
  }
}

.sc-tab--with-icon {
  background-color: $white;
  height: 36px;
  padding-top: $XS;
  width: 25%;
  opacity: .9;
  margin-bottom: 1px;
  margin-right: 1px;
  border: none;
  outline: none;

  @include mqmin(510px) {
    height: 47px;
    padding-top: $S;
  }

  as24-icon {
    width: 24px;
    height: 24px;
    display: inline-block;

    svg g {
      fill: $black-80;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    svg g {
      fill: $google-blue;
    }
  }
}

.sc-tab--with-icon--active {
  opacity: 1;
  height: 41px;
  padding-top: 3px;
  margin-bottom: 0;
  border-top: 4px solid $google-blue;

  @include mqmin(510px) {
    height: 52px;
    padding-top: 7px;
  }

  as24-icon svg {
    fill: $google-blue;
  }
}
