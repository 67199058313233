@import '../../../01-settings/variables';
@import '../../../02-sass-tools/link';

.sc-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0;

  li {
    text-align: center;
    line-height: 48px;
    min-height: 48px;
    min-width: 48px;
    max-width: 52px;
    list-style: none;
    flex: auto;
    border-top: 1px solid $black-20;
    border-bottom: 1px solid $black-30;

    &.previous-page, &.next-page {
      max-width: 200px;
      width: 100px;

      a { 
        color: $google-blue; 
      }

      a.disabled {
        svg path {
          stroke: $black-40;
        }
      }
    }

    &.previous-page {
      svg {
        transform: rotate(90deg);
      }
    }

    &.next-page {
      border-right: 1px solid $black-20;

      svg {
        transform: rotate(-90deg);
      }
    }

    &.info-page {
      max-width: inherit;
    }

    a {
      @include link(M);
      display: block;
      white-space: nowrap;
      transition: background-color .3s ease-in;
      background-color: $white;
      border-left: 1px solid $black-20;
      color: $black-100;

      &:hover {
        background-color: $black-30;
        border-left: 1px solid $black-30;
      }

      &.active {
        pointer-events: none;
        color: $black-100;
        background-color: $black-40;
        border-left: 1px solid $black-30;
      }

      &.disabled {
        pointer-events: none;
        color: $black-40;
      }

      as24-icon {
        bottom: 2px;
        display: inline;
        position: relative;
        // height: 7px;
        transition: fill $transition-time ease-in;
        // width: 13px;

        svg {
          width: 13px;
          height: 13px;
          margin-bottom: -4px;

          path {
            stroke: $color-link;
          }
        }
      }
    }

    @include mqmax($viewportS - 1) {
      display: none;

      &.previous-page, &.next-page {
        display: inline;
      }
    }
  }
}
