@import '../01-settings/variables';
@import '../02-sass-tools/sass-mq';

@mixin sc-skyscraper {
  min-height: 600px;
  line-height: 0;
  width: 160px;
  display: block;
  position: absolute;
  z-index: 110; // overlays navigation
  top: 0;
  padding: 0;
  overflow: hidden;
}

.has-ad-skyscraper-right {
  // 1270 - 1440
  @include mqminmax($viewportXXL, 1440px) {
    margin-right: 170px;
  }

  // 1100 - 1270
  @include mqminmax($viewportXL, $viewportXXL) {
    margin-left: 0;
  }
}

// Show superbanner first
.sc-superbanner {
  display: none;

  // show superbanner at 728px
  @include mqmin($viewportM - 40) {
    width: 1120px;
    line-height: 0;
    text-align: right;
    position: relative;
    left: -10px;
    display: block;
  }
}

// show right skyscraper as second
.sc-skyscraper-right {
  display: none;

  // show skyscraper at 1121px
  @include mqmin($viewportXL + 10) {
    @include sc-skyscraper();
    right: -170px;
  }
}

// show left skyscraper as third
.sc-skyscraper-left {
  display: none;

  // show skyscraper at 1271px
  @include mqmin($viewportXXL) {
    @include sc-skyscraper();
    left: -170px;
  }
}
