@import '../../01-settings/variables';

.sc-sticky {
  display: block;
  position: fixed;
  bottom: 32px; // so that iOS devices don't show the bottom browser bar when the user clicks the button
  width: 90%; // fallback
  width: calc(100% - 32px);
  z-index: 2;
  left: $L;

  @media only screen and (min-width: 500px) {
    width: 320px;
    left: 50%;
    margin-left: -160px;
  }
}
