// TODO: Do we still use spinners?
$size: 50px;
$spin-duration: 1s;

$orange: #FF7500;
$off-orange: #FFE3CC;

$white: #FFFFFF;
$off-white: rgba($white, 0.7);

$grey: #949494;
$off-grey: #D2D2D2;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sc-spinner-loading {
  border-radius: 50%;
  width: $size;
  height: $size;
  animation: spin $spin-duration infinite linear;
  border: 6px solid $off-white;
  border-top-color: $white;

  &.orange {
    border-color: $black-60 $off-grey $off-grey;
  }

  &.grey {
    border-color: $off-grey;
    border-top-color: $grey;
  }
}

.sc-spinner-value-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  position: relative;

  .sc-spinner-value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateY(32%)
  }

  .orange ~ .sc-spinner-value {
    color: $white;
  }

  .grey ~ .sc-spinner-value {
    color: $grey;
  }
}
