@import '../../../01-settings/variables';

@mixin input-group() {
  display: flex;
  flex-direction: row;
  width: 100%;

  > * {
    display: inline-block;
    min-width: 0;
  }
}

.sc-input-group {
  @include input-group;
  border: none;

  > * {
    position: relative;
    width: 100%;
    padding: 0 14px;

    &:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      margin-left: -1px;
    }

    &:hover,
    &:focus {
      z-index: 2;
    }
  }
}

.sc-input-group-radio {
  @include input-group;
  overflow: hidden;
  font-weight: 400;

  input[type=radio] {
    // display: none;

    &:disabled {
      & + label {
        cursor: not-allowed;
        color: $black-40;
        border-right: 1px solid $black-40;
      }

      &:checked + label {
        color: $black-60;
        background-color: $black-30;

        &:hover {
          background-color: $black-30;
        }
      }

      &:not(:checked) + label {
        background-color: $black-20;

        &:hover {
          background-color: $black-20;
        }
      }
    }

    &:focus + label, &:focus + label:last-of-type {
      box-shadow: inset 0 0 0 1px  $black-100;
      border-color: $black-100;
      border-right: 1px solid $black-100;
    }

    &:focus + label + input + label { // next label on the right have
      border-left: none; // to prevent doubled border
    }

    &:checked + label {
      background: $black-100;
      color: $white;
    }

    &:not(:checked) + label:hover {
      background: $black-50;
    }

    & + label {
      padding: 5px 0;
      margin: 0;
      text-align: center;
      cursor: pointer;
      color: $black-100;
      line-height: 1.8rem;
      height: 40px;
      transition: background-color $transition-time ease-in;
      border: 1px solid $black-60;
      border-right: none;

      &:last-of-type {
        border-top-right-radius: $form-border-radius;
        border-bottom-right-radius: $form-border-radius;
        border-right: 1px solid $black-60;
      }

      &:first-of-type {
        border-top-left-radius: $form-border-radius;
        border-bottom-left-radius: $form-border-radius;
      }
    }
  }

  > label {
    padding: 5px 0 !important;
    position: relative;
    width: 100%;
    flex: 1;

    as24-icon {
      line-height: 0;
      display: block;
    }

    &::before {
      display: none !important;
    }
  }
}
