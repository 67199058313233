@import '../../../01-settings/variables';

.sc-link-with-icon {
  &:hover {
    as24-icon svg {
      fill: $color-link-hover;
    }
    a {
      color: $color-link-hover;
    }
  }

  as24-icon {
    svg {
      fill: $color-link;
      max-width: 100%;
      max-height: 100%;
      transition: fill $transition-time;
    }
  }
}
