@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-lightbox__container {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: $form-border-radius;
  background-color: $white;
  padding: $L !important;
  z-index: 140; // overlay header
  max-height: calc(100vh - 80px);

  @include mqmin($viewportM) {
    padding: $XXXL !important;
  }

  @include mqmin(582px) {
    width: 550px;
  }

  @include mqmax(582px - 1) {
    width: calc(100% - 32px);
    max-height: calc(100vh - 120px);
  }

  &:first-of-type h1,
  &:first-of-type h2,
  &:first-of-type h3,
  &:first-of-type h4,
  &:first-of-type h5 {
    padding-right: $XXXL;
  }
}

.sc-unscroll {
  overflow-y: hidden !important;
}

.sc-lightbox__container--visible {
  display: flex;
}

.sc-lightbox__content {
  max-height: calc(100vh - 120px);
  overflow: auto;
  padding: 0 !important;
  flex: 1;

  @include mqmin($viewportM) {
    padding: 0 !important;
  }
}

.sc-lightbox__close {
  position: absolute;
  cursor: pointer;
  right: 21px;
  top: 21px;
  width: $XL;
  height:$XL;
  border: 2px solid $black-100;
  border-radius: 50%;
  background-color: $white;
  z-index: 3;

  &:before, &:after {
    position: absolute;
    left: 7px;
    top: 2px;
    border-radius:30%;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: $black-100;
  }
  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.sc-lightbox__close as24-icon {
  display: none !important;
}

.sc-lightbox__close:hover {
  border-color: $black-120;
  &:before, &:after {
    background-color: $black-120;
  }
}

.sc-lightbox__overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 139; // less than modal window by one
  opacity: 0;
  transition: opacity .1s ease;
}

.sc-lightbox__overlay--visible {
  display: block;
}

.sc-lightbox--fadein {
  opacity: 1;
}
