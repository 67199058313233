@import '../../../01-settings/variables';

custom-dropdown {
  div:first-child {
    height: auto;

    p {
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 0;
    }

    span {
      padding-left: $S;
      vertical-align: top;
    }
  }

  div:last-child {
    span {
      padding-left: $S;
      vertical-align: middle;
      margin-top: -$M;
      display: inline-block;
    }
  }

  as24-icon {
    width: auto;
    height: auto;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
