as24-icon {
  display: inline-block;
  width: auto;
  height: 16px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

button,
input {
  font-family: inherit; //Source Sans Pro, sans-serif;
  cursor: pointer;
  border-radius: 0;

  &[disabled] {
    cursor: not-allowed;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

a,
button,
input,
select,
textarea,
div,
span {
  &:focus {
    outline: 5px auto $black-100;
  }
}

img {
  max-width: 100%;
}
