@import '../../../01-settings/variables';
@import '../../../02-sass-tools/sass-mq';

.sc-superhero {
  background-color: $white;
  @include mqmin($viewportM) {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    justify-content: flex-end;
    padding: $XXXL;
    display: flex;
    min-height: 432px;
  }

  @include mqmin($viewportL) {
    min-height: 430px;
  }
}

.sc-superhero__imgs {
  display: flex;
  align-items: flex-end;
  padding: $L;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vw*.46);

  @include mqmin($viewportM) {
    min-height: 0;
    align-items: unset;
    padding: 0;
    display: block;
    padding-bottom: $L;
  }

  img {
    max-width: 100px;
    display: block;

    @include mqmin($viewportM) {
      max-width: 170px;
    }
  }
}

.sc-superhero__content {
  padding: $XXL $L $XXXL;

  @include mqmin($viewportM) {
    padding: 0;
    width: calc(75% - #{$XXXL});
  }

  @include mqmin($viewportL) {
    width: calc(50% - #{$XXXL});
  }
}

.sc-superhero__title {
  font-size: 24px;
}

.sc-superhero__content--light {
  @include mqmin($viewportM) {
    color: $white;
  }
}

.sc-superhero__title--branded {
  margin-bottom: 0;
}

.sc-superhero__wrapper {
  display: flex;
  flex-direction: column;

  .sc-superhero__content {
    padding-bottom: $XS;
  }

  @include mqmin($viewportM) {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .sc-superhero__content {
      margin-left: $XXL;
    }
  }
}

.sc-superhero__input--wrapper {
  border-bottom: 1px solid $black-30;

  @include mqmin($viewportM) {
    width: 480px;
    border-bottom: 0;
  }
}

.sc-superhero__input--content {
  background-color: $white;
  padding: 0 $L $XXL $L;

  @include mqmin($viewportM) {
    padding-top: $XL;
    padding-bottom: $XL;

    .sc-label {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}