@import '../../../01-settings/variables';
@import '../../../02-sass-tools/utils';

.sc-link-text-box {
  @include mqmin($viewportL) {
    display: flex;
  }
}

.sc-link-text-box__text {
  @include mqmin($viewportL) {
    flex-basis: 40%;
    padding-right: $XXL;
  } 
}

.sc-link-text-box__links {
  @include mqmin($viewportL) {
    flex-basis: 60%;
  } 
}

.sc-link-text-box__headline {
  padding-bottom: $L;
  font-weight: bold;
  font-size: $XL;
}

.sc-link-text-box__paragraph {
  padding-bottom: $XL;

  @include mqmin($viewportL) {
    padding-bottom: 0;
  }
}

.sc-link-text-box-group {
  .sc-link-text-box {
    border-bottom: 1px solid $black-30;
    margin-top: $XXXL;
    padding-bottom: $XXXL;

    @include mqmin($viewportM) {
      margin-top: $XXXXL;
      padding-bottom: $XXXXL;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}